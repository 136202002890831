.SingleDatePicker {
  position: relative;
  z-index: 600;
  display: block;
}

.DateInput {
  display: inline-flex;
}

.SingleDatePicker .DateInput_fang {
  z-index: 602;
}

.SingleDatePicker_picker {
  z-index: 601;
}

.SingleDatePickerInput .DateInput__small {
  width: 100%;
}

.DateInput_input__disabled {
  color: rgba(0, 0, 0, 0.25);
  font-style: normal;
  background-color: #f7f7f7;
  cursor: not-allowed;
}

.SingleDatePickerInput_calendarIcon {
  display: inline-flex;
}

.CalendarDay__selected_span {
  background: #0f84eb;
  color: white;
  border: 1px solid #0f84eb;
}

.CalendarDay__selected {
  background: #106ebe;
  color: white;
  border: 1px solid #106ebe;
}

.CalendarDay__selected:hover {
  background: #0f73cb;
  color: white;
  border: 1px solid #0f73cb;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #0f84eb;
  color: white;
  border: 1px solid #0f84eb;
}

.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #0f73cb;
  border-left: 0;
}

.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  font-weight: 400;
  padding: 7px 7px 4px 11px;
}

.DateInput_input__small::placeholder {
  color: rgb(215, 215, 215);
}

.SingleDatePickerInput_clearDates_default:hover {
  background: transparent;
  border-radius: 0%;
}

.CalendarMonth_caption {
  font-size: 15px;
}

.CalendarDay__blocked_calendar {
  border: 1px solid #e4e7e7;
  color: rgb(215, 215, 215);
  background: #fff;
}

.SingleDatePickerInput_clearDate__default:hover {
  background-color: #fff;
}

.SingleDatePickerInput_clearDate__small {
  padding: 6px;
  justify-content: center;
  display: flex;
}

.SingleDatePickerInput_clearDate {
  margin: 0 4px 0 4px;
}

.SingleDatePickerInput_calendarIcon {
  padding: 8px;
  margin: 0 4px 0 4px
}

.SingleDatePickerInput__disabled {
  background-color: #f7f7f7
}
