@import '~antd/dist/antd.variable.less';
@import '~@prio365/prio365-react-library/lib/index.less';
@import '~react-grid-layout/css/styles.css';
@import '~react-grid-layout/node_modules/react-resizable/css/styles.css';
@import './OverridePrioCL.less';

@navigation-bar-height: 64px;
@prio-primary-color: var(--ant-primary-color-outline);
@prio-menu-selected-font-weight: 600;

:root {
  --prio_primary: #1252c0;
  --prio_background_base: #2b3d4e;
  --prio_background_main: #dde0e2;
  --prio_background_sub: #f5f6f7;
  --prio_background_content: #ffffff;
  --prio_background_hover_base: #556471;
  --prio_background_hover_main: #d1d5d9;
  --prio_background_hover_sub: #e8eaec;
  --prio_background_hover_content: #f0f1f2;
  --prio_background_active_base: #c2c7cc;
  --prio_background_active_main: #b4c1d5;
  --prio_background_active_sub: #cdd5e3;
  --prio_background_active_content: #e6eaf1;
  --prio_borders_colors_base: #ffffff;
  --prio_borders_colors_main: #c2c7cc;
  --prio_borders_colors_sub: #dde0e2;
  --prio_borders_colors_content: #e8eaec;
  --prio_background_transparent: rgba(0, 0, 0, 0);
}

/** image preview **/
.ant-image-preview-operations-icon {
  color: #fff;
}
.ant-image-preview-operations {
  background-color: rgba(0, 0, 0, 0.8);
}

/** Font Awesome Icons **/
.ant-menu {
  .svg-inline--fa {
    color: @icon-color;
  }
}

/** Menus */
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin: 0;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0;
}

.ant-menu-item:active,
.ant-menu-item:hover,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
  background-color: var(--prio_background_hover_content);
}
.ant-menu-submenu-title:active {
  background-color: transparent;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu > .ant-menu-item-selected {
  position: relative;
  background-color: var(--prio_background_active_content);
  font-weight: @prio-menu-selected-font-weight;
}
.ant-menu-submenu-selected > .ant-menu-submenu-title {
  position: relative;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::before,
.ant-menu > .ant-menu-item-selected::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  left: 0;
  background-color: var(--prio_primary);
}

.ant-divider {
  border-color: var(--prio_borders_colors_content);
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  border-color: var(--prio_borders_colors_content);
}
.ant-input {
  border-color: var(--prio_borders_colors_content);
}
.ant-input[disabled] {
  border-color: var(--prio_borders_colors_content);
}
.ant-input-affix-wrapper {
  border-color: var(--prio_borders_colors_content);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: var(--prio_background_hover_content);
}
.ant-table-tbody > tr > td {
  border-color: var(--prio_borders_colors_content);
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: var(--prio_background_active_content);
  border-color: var(--prio_borders_colors_content);
}

.ant-select-selector {
  border-color: var(--prio_borders_colors_content);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--prio_borders_colors_content);
}

#prio-global-sider {
  #prio-main-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  #prio-main-menu.ant-menu > .ant-menu-item-selected {
    background-color: var(--prio_background_active_content);
    font-weight: @prio-menu-selected-font-weight;
  }
  #prio-main-menu .ant-menu-item:active,
  #prio-main-menu .ant-menu-item:hover,
  #prio-main-menu
    .ant-menu:not(.ant-menu-horizontal)
    .ant-menu-item-selected:hover {
    background-color: var(--prio_background_hover_content);
  }
  .ant-menu-item:active,
  .ant-menu-item:hover,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
    background-color: var(--prio_background_hover_main);
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu > .ant-menu-item-selected {
    background-color: var(--prio_background_active_main);
  }
  .ant-layout-sider-trigger .ant-btn:hover,
  .ant-layout-sider-trigger a > div:hover {
    background-color: var(--prio_borders_colors_main);
  }
  .ant-divider {
    border-color: var(--prio_borders_colors_main);
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    border-color: var(--prio_borders_colors_main);
  }
  .ant-input {
    border-color: var(--prio_borders_colors_main);
  }
  #prio-global-sider-submenu {
    .ant-divider {
      border-color: var(--prio_borders_colors_content);
    }
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
      border-color: var(--prio_borders_colors_content);
    }
    .ant-input {
      border-color: var(--prio_borders_colors_content);
    }
  }
}

#prio-global-navigation-bar {
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active {
    background-color: var(--prio_background_base);
  }
}

#prio-module-navigation-bar {
  background-color: var(--prio_background_sub);
  border-bottom: 1px solid var(--prio_borders_colors_sub);
  .ant-btn:not(.ant-btn-primary) {
    background-color: var(--prio_background_sub);
  }
}
.prio-module-navigation {
  background-color: var(--prio_background_sub);
  border-right: 1px solid var(--prio_borders_colors_sub);

  .ant-menu-item:active,
  .ant-menu-item:hover,
  .ant-menu-item-active:hover,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
    background-color: var(--prio_background_hover_sub);
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu > .ant-menu-item-selected {
    background-color: var(--prio_background_active_sub);
    font-weight: @prio-menu-selected-font-weight;
  }
  .ant-divider {
    border-color: var(--prio_borders_colors_sub);
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    border-color: var(--prio_borders_colors_sub);
  }
  .ant-input {
    border-color: var(--prio_borders_colors_sub);
  }
}

.ant-menu-item-selected {
  .svg-inline--fa {
    color: currentColor;
  }
}

.ant-menu .ant-menu-item-selected:after {
  display: none;
}

.ant-dropdown-menu-item > a:hover {
  color: @prio-primary-color;
}
.ant-dropdown-trigger.ant-btn {
  background-color: transparent;
}

.ant-list-item-action-split {
  display: none;
}

.ant-list-item-meta-description {
  font-size: @font-size-base - 1px;
}

.ant-btn {
  border: none;
  box-shadow: none;
  padding: 3.2px 12px;
}
.ant-btn-icon-only {
  padding: 1.6px 0;
}

.ant-btn > .svg-inline--fa + span,
.ant-btn > span + .svg-inline--fa {
  margin-left: 8px;
}

.ant-btn:not(.ant-btn-primary),
.ant-table-cell {
  > .svg-inline--fa {
    color: @icon-color;
  }
}

h1.ant-typography {
  font-size: 24px;
}

h2.ant-typography {
  font-size: 20px;
}

h3.ant-typography {
  font-size: 18px;
}

h4.ant-typography {
  font-size: 16px;
}

@keyframes shake {
  100%,
  28%,
  18%,
  9%,
  0% {
    transform: rotate(0deg);
  }
  21%,
  12%,
  3% {
    transform: rotate(-10deg);
  }
  24%,
  15%,
  6% {
    transform: rotate(10deg);
  }
}
.prio {
  &-flex-center-center {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &-flex-center-center-navigation-bar {
    display: flex;
    width: 100%;
    height: calc(100% - @navigation-bar-height);
    align-items: center;
    justify-content: center;
  }

  &-flex-row {
    flex-direction: row;
  }

  &-flex-column {
    flex-direction: column;
  }

  &-container {
    &-fullscreen {
      &-height {
        height: 100vh;
      }

      &-width {
        width: 100vh;
      }
    }
  }
}

.ant-form-vertical {
  .ant-form-item-label {
    padding-bottom: 4px;

    > label {
      color: @text-color-secondary;
      font-size: @font-size-base - 1px;
    }
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  color: @text-color-secondary;
  font-size: 16px;
  line-height: 1;
  content: '*';
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
  content: '';
}

.ant-table-column-sorters > span:first-child {
  color: @prio-primary-color;
}

th.ant-table-cell {
  font-size: @font-size-base - 1;
}

.ant-collapse {
  > .ant-collapse-item {
    > .ant-collapse-header {
      padding-left: 34px;

      > .ant-collapse-arrow {
        font-size: 10px;
      }
    }
  }
}

.ant-alert-error {
  background-color: @error-color;

  & * {
    color: @text-color-inverse !important;
  }
}

.ant-alert-with-description .ant-alert-message {
  margin-bottom: 16px;
}

.ant-alert.ant-alert-no-icon {
  border: none;
  padding: 16px;

  &.ant-alert-info {
    background-color: @muted-color;

    & * {
      color: @text-color-inverse !important;
    }
  }
  &.ant-alert-success {
    background-color: @prio-color-green;

    & * {
      color: @text-color-inverse !important;
    }
  }
}

.ant-drawer-content-wrapper {
  max-width: 1024px;
}
.ant-drawer-header-close-only {
  padding: 0;
}
.ant-drawer-header .ant-drawer-header-close-only,
.ant-drawer-header-title {
  display: block;
  > button {
    margin-right: 4px;
    padding: 20px 16px 20px 20px;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.ant-btn[disabled] {
  background-color: transparent;
  border-color: transparent;
}

.ant-list-item,
.ant-table-row {
  .ant-checkbox:not(.ant-checkbox-checked) {
    visibility: hidden;
  }

  &:hover {
    .ant-checkbox {
      visibility: visible;
    }
  }
}

::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  height: 6px;
  &:hover {
    width: 12px;
  }
}

::-webkit-scrollbar-thumb {
  background: @prio-primary-color;
  border-radius: 6px;
  &:hover {
    width: 12px;
  }
}

#documentsModule-table-body-container::-webkit-scrollbar {
  background: transparent;
  width: 6px;
}

#documentsModule-table-body-container::-webkit-scrollbar-thumb {
  background: @prio-primary-color;
  border-radius: 3px;
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: rgba(255, 255, 255, 0.4);
}

.ant-layout-sider-zero-width-trigger {
  background-color: @prio-primary-color;
  top: 12px;
}

.ant-layout-sider-children {
  > * {
    height: 100%;
  }
}

.ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}

.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  &::before,
  &::after {
    height: 0.75px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    );
  }
  &::before {
    transform: rotate(-45deg) translateX(3px);
  }
  &::after  {
    transform: rotate(45deg) translateX(-3px);
  }
}
.ant-menu-submenu-open.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow {
  &::before {
    transform: rotate(45deg) translateX(3px);
  }
  &::after  {
    transform: rotate(-45deg) translateX(-3px);
  }
}

.ant-table-column-sorters > span:first-child,
.ant-table-thead > tr > th {
  color: var(--prio_primary);
}

#cypress-wrapper > div {
  width: 100%;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border: 1px solid var(--prio_borders_colors_sub);
}

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: var(--prio_borders_colors_sub);
  color: var(--prio_primary);
}

.ant-tabs-dropdown-menu-item span,
.ant-tabs-dropdown-menu-item span {
  display: block;
}

.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid var(--prio_borders_colors_content);
  display: flex;
  height: 33px;
}

.prio-new-message-notification .ant-notification-notice-message,
.prio-new-message-notification .ant-notification-notice-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@text-color: #000;@font-size-base: 15px;@error-color: #CF634B;@table-header-bg: #fff;@table-body-sort-bg: #fff;@table-header-sort-bg: #fff;@table-footer-bg: #fff;@icon-color: rgba(0,0,0,0.6);@muted-color: rgba(0,0,0,0.6);@prio-color-green: #3E9871;@layout-sider-background: #F7F8FA;