.prio-deprecated-design {
  .prio-checkbox-input {
    border: 1px solid var(--borders_colors_disabled);
    &:checked 
    {border :none;}
  }
  .prio-button-default-type {
    &:not(:hover){background-color: var(--prio_background_transparent);}
    &:hover {
      color: var(--prio_primary);
    }
  }
  .prio-button-disabled-primary {
      background-color: transparent;
  }
}
